<template>
  <v-container>
    <!-- change this to last page in history, once pagination is added? -->
    <v-btn small outlined color="#862042" :to="{ name: 'collection' }">
      <v-icon dark left> mdi-arrow-left </v-icon>Back to collection
    </v-btn>

    <!-- image viewer -->
    <LeafletIiifViewer :infoJson="infoJsonLink" class="mt-8" />
    <!-- demo info -->
    <div class="container has-text-left mt-5">
      <h2 class="title is-5">About</h2>
      <p>Text here</p>
    </div>
  </v-container>
</template>

<script>
import LeafletIiifViewer from "~components/LeafletIiifViewer";
export default {
  name: "Painting",
  components: {
    LeafletIiifViewer,
  },
  created() {
    const artworkId = this.$route.params.id;
    console.log("id", artworkId);
  },
  computed: {
    infoJsonLink() {
      const artworkId = this.$route.params.id;
      return (
        "https://szmoon.github.io/tien-wei-chang-images/images/" +
        artworkId +
        "/info.json"
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// @import "@/assets/main.scss";
</style>
